import React, { useState } from 'react';
import { Router } from '@reach/router';
import {
  Button, Col, Divider, Row,
} from 'antd';
import { BaseLayout } from '../../../components/layout/base/base-layout';
import { AuthForm } from '../../../components/auth/auth-form';
import { SignUpConfirmPage } from '../../../components/auth/sign-up/sign-up-confirm-page';
import { SEO } from '../../../components/layout/seo';
import { PasswordResetPage } from '../../../components/auth/password-reset/password-reset-page';
import { CenteredSection } from '../../../components/layout/sections/centered/centered-section';
import { ContactModal } from '../../../components/cards/contact-modal/contact-modal';

const AuthPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <BaseLayout>
      <SEO title="Join" />
      <CenteredSection>
        <Router basepath="/user/auth/">
          <AuthForm path="*" />
          <SignUpConfirmPage path="confirm/*" />
          <PasswordResetPage path="reset/*" />
        </Router>
        <Divider />
        <Row justify="center">
          <Col>
            <Button type="dashed" onClick={() => setIsModalVisible(true)}>
              Are you a supplier?
            </Button>
          </Col>
        </Row>
      </CenteredSection>
      <ContactModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </BaseLayout>
  );
};

export default AuthPage;
