import {
  Card, Divider, Modal, Space, Typography,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { FcAbout, FcCallback } from 'react-icons/all';
import { useShop } from '../../../graphql/static-hooks/useShop';

export const ContactModal = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const shop = useShop();
  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      title="Contact"
      footer={null}
    >
      <Typography.Paragraph>
        We are working on adding better communication tools like real-time chat,
        forms, and integrated RFPs. In the meantime you can get in touch using:
      </Typography.Paragraph>
      <Divider />
      <Space direction="vertical">
        <Card>
          <Card.Meta
            avatar={<FcCallback />}
            title="Phone"
            description={shop.companyAddress?.phone || '+34 931 785 300'}
          />
        </Card>
        <Card>
          <Card.Meta
            avatar={<FcAbout />}
            title="Email"
            description={shop.defaultMailSenderAddress || 'contact@akommo.com'}
          />
        </Card>
      </Space>
    </Modal>
  );
};

ContactModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
};
