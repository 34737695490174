import { gql } from '@apollo/client';

export const SET_PASSWORD = gql`
    mutation SetPassword($email: String!, $token: String!, $password: String!){
        setPassword(email: $email, token: $token, password: $password) {
            accountErrors {
                field
                message
                code
            }
        }
    }`;
