import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { message, Result, Spin } from 'antd';
import Button from 'antd/lib/button/button';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { useMutation } from '@apollo/client';
import { ACCOUNT_CONFIRM } from '../../../graphql/mutations/accountConfirm';

export const SignUpConfirmPage = (params) => {
  const searchParams = new URLSearchParams(params.location?.search);
  const [statusMessage, setStatusMessage] = useState('Verifying email');

  const postAccountConfirm = async (result) => {
    if (result.confirmAccount.accountErrors.length === 0) {
      message.success('Activated successfully');
      navigate('/user/auth/');
    }
    result.confirmAccount.accountErrors.map(
      ((err) => message.error(`${err.message}`)),
    );
  };

  const [accountConfirm, { data }] = useMutation(ACCOUNT_CONFIRM, {
    errorPolicy: 'all',
    onCompleted: postAccountConfirm,
  });

  useEffect(() => {
    if (searchParams.get('token') && searchParams.get('email')) {
      setStatusMessage('Activating account');
      accountConfirm({
        variables:
          {
            email: searchParams.get('email'),
            token: searchParams.get('token'),
          },
      });
    }
  }, []);

  if (!params.location?.search) {
    return (
      <Result
        icon={<HiOutlineMailOpen />}
        title="Great, we've sent an activation link to your email"
        subTitle="It should arrive within a few minutes."
        extra={[
          <Link to="/support/">
            <Button size="small" type="dashed">
              Having an issue? Message
              us
            </Button>
          </Link>]}
      />
    );
  }

  if (!searchParams.get('token') || !searchParams.get('email')
    || data?.confirmAccount?.accountErrors?.length > 0) {
    return (
      <Result
        status="404"
        title="The activation link you have followed seems to be broken"
        subTitle="Please, try to copy the link from the email to the browser and reload the page"
      />
    );
  }

  return (
    <Spin tip={statusMessage} size="large" />
  );
};

SignUpConfirmPage.propTypes = {};
