import React from 'react';
import { Link, navigate } from 'gatsby';
import {
  Card, Divider, Form, Input, message, Result, Space,
} from 'antd';
import Button from 'antd/lib/button/button';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { useMutation } from '@apollo/client';
import { AiOutlineLock, AiOutlineMail } from 'react-icons/all';
import { SET_PASSWORD } from '../../../graphql/mutations/setPassword';
import { formItemLayout } from '../utils/form-item-layout';

export const PasswordResetPage = (params) => {
  const searchParams = new URLSearchParams(params.location?.search);

  const postSetPassword = async (result) => {
    if (result.setPassword.accountErrors.length === 0) {
      message.success('New password set');
      navigate('/user/auth/');
    }
    result.setPassword.accountErrors.map(
      ((err) => message.error(`${err.message}`)),
    );
  };

  const [setPassword, {
    loading,
    data,
  }] = useMutation(SET_PASSWORD, {
    errorPolicy: 'all',
    onCompleted: postSetPassword,
  });

  if (!params.location?.search) {
    return (
      <Result
        icon={<HiOutlineMailOpen />}
        title="Great, we've sent a password reset link to your email"
        subTitle="It should arrive within a few minutes."
        extra={[
          <Link to="/support/">
            <Button size="small" type="dashed">
              Having an issue? Message
              us
            </Button>
          </Link>]}
      />
    );
  }

  if (!searchParams.get('token') || !searchParams.get('email')
    || data?.setPassword?.accountErrors?.length > 0) {
    return (
      <Result
        status="404"
        title="The activation link you have followed seems to be broken"
        subTitle="Please, try to copy the link from the email to the browser and reload the page"
      />
    );
  }

  return (
    <Card
      title="Set a new password"
      style={{ minWidth: '450px' }}
    >
      <Form
        size="large"
        name="reset"
        {...formItemLayout}
        onFinish={(values) => setPassword({
          variables: {
            email: searchParams.get('email'),
            password: values.password,
            token: searchParams.get('token'),
          },
        })}
      >
        <Form.Item
          label="Email"
        >
          <Input
            disabled
            prefix={<AiOutlineMail />}
            defaultValue={searchParams.get('email')}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
            {
              min: 9,
              message: 'Make it secure: use 9 symbols or more',
            },
          ]}
        >
          <Input.Password
            placeholder="Letters, numbers & symbols"
            prefix={<AiOutlineLock />}
          />
        </Form.Item>
        <Divider />
        <Space className="form-actions">
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              Reset
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Card>
  );
};

PasswordResetPage.propTypes = {};
