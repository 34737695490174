import { gql } from '@apollo/client';

export const ACCOUNT_CONFIRM = gql`
    mutation AccountConfirm($email: String!, $token: String!){
        confirmAccount(email: $email, token: $token) {
            user {
                id
            }
            accountErrors {
                field
                message
                code
            }
        }
    }`;
